import { createSelector } from 'reselect';
import isNumeric from 'validator/lib/isNumeric';
import sortBy from 'lodash.sortby';
import startsWith from 'lodash.startswith';
import { findZone, DEFAULT_PREFIX, MAX_COST, findCost } from './HotelUtils';

export const getFetchHotelAreaPrefixesLoaded = (state) =>
  state.hotel.fetchHotelAreaPrefixesLoaded;
export const getFetchHotelAreaPrefixesError = (state) =>
  state.hotel.fetchHotelAreaPrefixesError;
export const getFetchHotelPricesLoaded = (state) =>
  state.hotel.fetchHotelPricesLoaded;
export const getFetchHotelPricesError = (state) =>
  state.hotel.fetchHotelPricesError;
export const getSaveHotelPriceLoaded = (state) =>
  state.hotel.saveHotelPriceLoaded;
export const getSaveHotelPriceError = (state) =>
  state.hotel.saveHotelPriceError;
export const getDeleteHotelPriceLoaded = (state) =>
  state.hotel.deleteHotelPriceLoaded;
export const getDeleteHotelPriceError = (state) =>
  state.hotel.deleteHotelPriceError;
export const getResetHotelPricesLoaded = (state) =>
  state.hotel.resetHotelPricesLoaded;
export const getResetHotelPricesError = (state) =>
  state.hotel.resetHotelPricesError;
export const getFetchHotelCallsLoaded = (state) =>
  state.hotel.fetchHotelCallsLoaded;
export const getFetchHotelCallsError = (state) =>
  state.hotel.fetchHotelCallsError;
export const getHotelCallsLogFilter = (state) => state.hotel.cdr.filter;
export const getToggleHotelWakeupServiceLoaded = (state) =>
  state.hotel.toggleHotelWakeupServiceLoaded;
export const getToggleHotelWakeupServiceError = (state) =>
  state.hotel.toggleHotelWakeupServiceError;
export const getFetchHotelWakeupSettingsLoaded = (state) =>
  state.hotel.fetchHotelWakeupSettingsLoaded;
export const getFetchHotelWakeupSettingsError = (state) =>
  state.hotel.fetchHotelWakeupSettingsError;
export const getUpdateHotelWakeupSettingsLoaded = (state) =>
  state.hotel.updateHotelWakeupSettingsLoaded;
export const getUpdateHotelWakeupSettingsError = (state) =>
  state.hotel.updateHotelWakeupSettingsError;
export const getUpdateHotelWakeupSettingsSuccess = (state) =>
  state.hotel.updateHotelWakeupSettingsSuccess;
export const getFetchHotelWakeupsLoaded = (state) =>
  state.hotel.fetchHotelWakeupsLoaded;
export const getFetchHotelWakeupsError = (state) =>
  state.hotel.fetchHotelWakeupsError;
export const getSaveHotelWakeupLoaded = (state) =>
  state.hotel.saveHotelWakeupLoaded;
export const getSaveHotelWakeupError = (state) =>
  state.hotel.saveHotelWakeupError;
export const getDeleteHotelWakeupLoaded = (state) =>
  state.hotel.deleteHotelWakeupLoaded;
export const getDeleteHotelWakeupError = (state) =>
  state.hotel.deleteHotelWakeupError;

const getHotelPricesById = (state) => state.hotel.prices.byId;
const getHotelPricesIds = (state) => state.hotel.prices.allIds;
export const getHotelPrices = createSelector(
  [getHotelPricesById, getHotelPricesIds],
  (pById, pIds) => [
    ...sortBy(
      pIds.map((o) => pById[o]),
      'prefix'
    ).filter((o) => o.prefix !== '*'),
    ...pIds.map((o) => pById[o]).filter((o) => o.prefix === '*'),
  ]
);
export const getHotelAreaPrefixes = (state) => state.hotel.areaPrefixes;
export const getHotelZonePriceByPrefix = (state, prefix) => {
  if (!prefix) return;
  if (!isNumeric(prefix) && prefix !== DEFAULT_PREFIX) return;
  const zone = findZone(prefix, state.hotel.areaPrefixes);
  if (zone) {
    const areaPrefixes = state.hotel.areaPrefixes.filter((p) =>
      startsWith(p.prefix, zone.prefix)
    );

    const price =
      zone.price || sortBy(areaPrefixes, 'price').reverse()[0].price;
    return { ...zone, areaPrefixes, price };
  }
  return null;
};
export const getHotelZonePriceByPrefixFunction = (state) => (number) =>
  getHotelZonePriceByPrefix(state, number);
export const getHotelCdrCalls = (state) => state.hotel.cdr.calls;
export const getHotelCalls = createSelector(
  [getHotelPrices, getHotelCdrCalls, getHotelZonePriceByPrefixFunction],
  (prices, cdrCalls, retrieveHotelZoneFunction) => {
    let rows = [];
    let total = 0;
    cdrCalls.forEach((o) => {
      let call = o;
      if (o.called) {
        let number = o.called.replace(/\*/g, '');
        if (number.length > 1) {
          if (number.substr(0, 2) !== '00') {
            number = `39${number}`;
          } else {
            number = number.substr(2);
          }
          const cost = findCost(number, prices);
          const zone = retrieveHotelZoneFunction(number);
          if (cost && cost.price) {
            call = {
              ...call,
              cost: cost.price,
              zoneDescription: (zone && zone.description) || '',
              total: (cost.price * call.duration) / 60,
            };
          }
          if (cost && cost.percentage) {
            const price = +zone.price || MAX_COST;
            call = {
              ...call,
              price,
              percentage: cost.percentage,
              zoneDescription: (zone && zone.description) || '',
              total:
                (((price * cost.percentage) / 100 + price) * call.duration) /
                60,
            };
          }
          if (call.total) total += call.total;
        }
      }
      rows = [...rows, call];
    });
    return { rows, total };
  }
);
export const getHotelCallCostByNumber = (state, number) => {
  if (!number) return;
  const prices = getHotelPrices(state);
  const cost = findCost(number, prices);
  if (cost && cost.price) return cost.price;
  if (cost && cost.percentage) {
    const zone = getHotelZonePriceByPrefix(state, cost.prefix) || 1;
    return zone.price * cost.percentage;
  }
  return null;
};
export const getHotelWakeupSettings = (state) => state.hotel.wakeupSettings;
const getHotelWakeupsById = (state) => state.hotel.wakeups.byId;
const getHotelWakeupsIds = (state) => state.hotel.wakeups.allIds;
export const getHotelWakeups = createSelector(
  [getHotelWakeupsById, getHotelWakeupsIds],
  (pById, pIds) => pIds.map((o) => pById[o])
);
export const getHotelActiveWakeupsFilter = (state) =>
  state.hotel.activeWakeupsFilter;
export const getHotelArchiveWakeupsFilter = (state) =>
  state.hotel.archiveWakeupsFilter;
export const getHotelWakeupById = (state, id) => state.hotel.wakeups.byId[id];
export const getLastProtelSync = (state) => state.hotel.lastProtelSync;
export const isLastProtelSyncLoaded = (state) =>
  state.hotel.lastProtelSyncLoaded;
export const isLastProtelSyncError = (state) =>
  state.hotel.lastProtelSyncError;
export const isHotelProtelSyncSaved = (state) =>
  state.hotel.protelSyncSaveLoaded;
export const isHotelProtelSyncError = (state) =>
  state.hotel.protelSyncSaveError;
export const getNewSyncProtelData = (state) =>
  state.hotel.newSyncData;
export const isNewSyncProtelError = (state) =>
  state.hotel.newSyncError;
export const isNewSyncProtelLoaded = (state) =>
  state.hotel.newSyncLoaded;
export const isEditProtelNameError = (state) =>
  state.hotel.editProtelNameError;
import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import SectionTitle from '../../../components/SectionTitle';
import GlobalErrorPage from '../../pages/GlobalErrorPage';
import { getCustomerId, hasGrants } from '../../../js/me/selectors';
import ErrorBoundary from '../../../components/ErrorBoundary';
import HelpButton from '../../../components/HelpButton';

const HotelProtelCurrent = React.lazy(() => import('./HotelProtelCurrent'));
const HotelProtelUpload = React.lazy(() => import('./HotelProtelUpload'));

const messages = defineMessages({
  title: {
    id: 'HotelProtelPage.title',
    defaultMessage: 'Protel integration',
  },
});

const HotelProtelPage = (props) => (
  <React.Fragment>
    {!props.canSee ? (
      <GlobalErrorPage code={403} />
    ) : (
      <div className="border rounded h-100 bg-white">
        <SectionTitle>{props.intl.formatMessage(messages.title)}</SectionTitle>
        <ErrorBoundary>
          <React.Suspense fallback={<div />}>
            <div className="text-right mb-2 mr-4">
              <HelpButton fileName="cdr-protel" />
            </div>
            <div className="yn-section-scrolling p-3">
              <HotelProtelUpload />
              <HotelProtelCurrent />
            </div>
          </React.Suspense>
        </ErrorBoundary>
      </div>
    )}
  </React.Fragment>
);

function mapStateToProps(state) {
  return {
    canSee: hasGrants(state, 'PROTEL_ENABLED') && state.me.customer.hotelEnabled && getCustomerId(state) === 2796,
  };
}

export default injectIntl(connect(mapStateToProps)(HotelProtelPage));

import { eventChannel } from 'redux-saga';
import { request } from '../request';
import { retrieveApiEndpoint } from './FileUtils';
import ynConf from '../../conf';
import { editProtelName } from '../hotel/sagas';

// isAppDesktop
const { ipcRenderer } = window;
const baseUrl = ipcRenderer ? ynConf.fileBaseUrl : '/files/v1';

export default {
  updateMeAvatar: (avatar) =>
    request(
      {
        url: '/avatar/me',
        method: 'PUT',
        data: { avatar: avatar.image },
      },
      {
        baseUrl,
      }
    ),
  updateUserAvatar: (avatar) =>
    request(
      {
        url: `/avatar/users/${avatar.id}`,
        method: 'PUT',
        data: { avatar: avatar.image },
      },
      {
        baseUrl,
      }
    ),
  createContactAvatar: (avatar) =>
    request(
      {
        url: `/avatar/contacts`,
        method: 'PUT',
        data: { avatar: avatar.image },
      },
      {
        baseUrl,
      }
    ),
  updateContactAvatar: (avatar) =>
    request(
      {
        url: `/avatar/contacts/${avatar.id}`,
        method: 'PUT',
        data: { avatar: avatar.image },
      },
      {
        baseUrl,
      }
    ),
  uploadFiles: (params, config) =>
    request(
      {
        url: retrieveApiEndpoint(params.scope, null, params.entityId),
        method: 'PUT',
        data: params.formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      {
        ...config,
        baseUrl,
      }
    ),
  removeFile: () =>
    request({
      url: '/me',
      method: 'GET',
    }),
  createUploadFilesChannel: (params, cancelToken) =>
    eventChannel((emitter) => {
      request(
        {
          url: retrieveApiEndpoint(params.scope, params.fileId),
          method: 'PUT',
          data: params.formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        },
        {
          baseUrl,
          onUploadProgress: (progressEvent) => {
            let progressData = 0;
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader
              ? progressEvent.target.getResponseHeader('content-length') ||
                progressEvent.target.getResponseHeader(
                  'x-decompressed-content-length'
                )
              : null;
            if (totalLength !== null) {
              progressData = Math.round(
                (progressEvent.loaded * 100) / totalLength
              );
            }
            emitter({ progress: progressData });
          },
          cancelToken,
        },
        false
      )
        .then((res) => emitter({ success: res }))
        .catch((err) => {
          emitter({ error: err });
        });
      return () => {};
    }),
  importFileToFilebox: (data) =>
    request(
      {
        url: `/filebox/import`,
        method: 'PUT',
        data,
      },
      {
        baseUrl,
      }
    ),
  deleteMailofficeRR: (mailofficeId) =>
    request(
      {
        url: `/mailoffice/${mailofficeId}/return-receipt`,
        method: 'DELETE',
      },
      {
        baseUrl,
      }
    ),
  newProtelSync: (formData) =>
    request(
      {
        url: '/protel',
        method: 'PUT',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      {
        baseUrl,
      }
    ),
};
